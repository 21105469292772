<template>
  <!-- 线上课程 -->
  <div class="con_contain">
    <div class="mainpage">
      <div class="head">
        <div class="searchcontain">
          <input
            class="elinput"
            placeholder="请输入课程名称"
            v-model="queryParams.keyword"
            type="text"
          />

          <div class="searchbt" @click="searchbt">搜索</div>
        </div>
      </div>
      <!-- <div class="line"></div> -->
      <div class="titlecategory">
        <div class="titlecategory-item">
          <div class="item-left">学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;院</div>
          <div class="item-right">
            <div
              :class="
                queryParams.college_id === ''
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="academybtclick('')"
            >
              全部
            </div>
            <div
              :class="
                queryParams.college_id == item.id
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="academybtclick(item.id)"
              v-for="(item, index) in collegelist"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="titlecategory-item">
          <div class="item-left">学科分类</div>
          <div class="item-right">
            <div
              :class="
                queryParams.subject_config_id === ''
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="subjectbtclick('')"
            >
              全部
            </div>
            <div
              :class="
                queryParams.subject_config_id == item.id
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="subjectbtclick(item.id)"
              v-for="(item, index) in subjectclassification"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="titlecategory-item">
          <div class="item-left">课程类别</div>
          <div class="item-right">
            <div
              :class="
                queryParams.course_type_config_id === ''
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="coursetypeclick('')"
            >
              全部
            </div>
            <div
              :class="
                queryParams.course_type_config_id == item.id
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="coursetypeclick(item.id)"
              v-for="(item, index) in coursetype"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="titlecategory-item">
          <div class="item-left">排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序</div>
          <div class="item-right">
            <div
              :class="
                queryParams.sort_type == 2
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="sortbtclick(2)"
            >
              加入人数最多
            </div>
            <div
              :class="
                queryParams.sort_type == 1
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="sortbtclick(1)"
            >
              最新上线
            </div>
            <div
              :class="
                queryParams.sort_type == 3
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              "
              @click.stop="sortbtclick(3)"
            >
              收藏最多
            </div>
          </div>
        </div>
      </div>
      <div class="image-contain">
        <div class="image-item" v-if="courselist && courselist.length > 0">
          <div
            class="image-item-item"
            @click="todetail(item)"
            v-for="(item, index) in courselist"
            :key="index"
          >
            <div
              class="card-contain"
              :class="
                item.is_join && Number(item.child_type) ? 'join_status' : ''
              "
            >
              <span
                v-if="item.is_join && Number(item.child_type)"
                class="farm_tag"
                >已报名</span
              >
              <div style="background: aliceblue; text-align: center">
                <el-image
                  :class="{
                    elimage: item.cover != null,
                    elimage1: item.cover == null,
                  }"
                  v-if="Number(item.child_type) === 0"
                  :src="item.cover ? item.cover : image09"
                  @error="handleError"
                />
                <el-image
                  class="elimage"
                  v-else
                  :src="item.home_cover ? item.home_cover : image09"
                  @error="handleError"
                />
              </div>

              <!-- background: aliceblue; -->

              <div class="card-title">{{ item.name }}</div>
              <div class="title2">
                <div class="name">
                  {{
                    item.teacher_user_info ? item.teacher_user_info.name : ""
                  }}
                </div>
                <div class="rightimage">
                  <el-image class="elimage" :src="image3"></el-image>
                  <span class="text">{{ item.join_count }}人参与</span>
                  <!-- <span class="text">{{ item.view_count }}点击率</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="36"
          :current-page="queryParams.page"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseList } from "@/api/course";
import { getcollegelist, gettypeconfiglist } from "@/api/toptype";
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      courseinfo: "",
      queryParams: {
        keyword: "",
        college_id: "",
        subject_config_id: "",
        course_type_config_id: "",
        sort_type: 2,
        per_page: 36,
        page: 1,
      },
      courselist: [],
      collegelist: [],
      subjectclassification: [],
      coursetype: [],
      total: 0,
      image3: require("@/assets/new/people.png"),
      testimage: require("@/assets/test.png"),
      testimageicon: require("@/assets/矩形图标.png"),
      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
      image09: require("@/assets/defaultImage.png"),
      isMobile: "",
    };
  },
  computed: {},
  created() {
    this.getcollegelist({ record_type: 1 }); //record_type 是否获取全部记录 0：分页，1：全部
    this.gettypeconfiglist0({ type: 0 }); //0：学科分类 27：虚拟仿真级别
    this.getcoursetype({ type: 29 }); //0：学科分类 27：虚拟仿真级别
    const queryObj = this.$route.query
    if (queryObj.course_type_config_id) {
      this.coursetypeclick(queryObj.course_type_config_id);
    } else {
      if (this._isMobile()) {
        this.isMobile = 0;
        this.getCourseList();
      } else {
        this.isMobile = 1;
        this.getCourseList();
      }
    }
  },
  methods: {
    handleError(e) {
      // 当图片加载失败时，将图片地址设置为默认图片
      e.target.src = this.image09;
      // 可以添加额外的处理，比如提示用户或者进行日志记录
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },

    searchbt() {
      this.queryParams.page = 1;
      this.getCourseList();
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val;
      this.getCourseList();
    },
    academybtclick(id) {
      this.queryParams.college_id = id;
      this.queryParams.page = 1;
      this.getCourseList();
    },
    subjectbtclick(id) {
      this.queryParams.subject_config_id = id;
      this.queryParams.page = 1;
      this.getCourseList();
    },
    coursetypeclick(id) {
      this.queryParams.course_type_config_id = id;
      this.queryParams.page = 1;
      this.getCourseList();
    },

    sortbtclick(id) {
      this.queryParams.sort_type = id;
      this.queryParams.page = 1;
      this.getCourseList();
    },

    getcollegelist(params) {
      this.collegelist = [];
      const condition = sessionStorage.getItem("college_all");
      if (condition) {
        this.collegelist = JSON.parse(condition);
      } else {
        getcollegelist(params)
          .then((response) => {
            if (response.data && response.data.length > 0) {
              response.data.forEach((item) => {
                this.collegelist.push({
                  id: item.id,
                  name: item.name,
                });
              });
            }
          })
          .catch(() => {});
      }
    },
    gettypeconfiglist0(params) {
      this.subjectclassification = [];
      const condition = sessionStorage.getItem("subject_all");
      if (condition) {
        this.subjectclassification = JSON.parse(condition);
      } else {
        gettypeconfiglist(params)
          .then((response) => {
            if (response.data && response.data.length > 0) {
              response.data.forEach((item) => {
                this.subjectclassification.push({
                  id: item.id,
                  name: item.name,
                });
              });
            }
          })
          .catch(() => {});
      }
    },
    getcoursetype(params) {
      this.coursetype = [];
      const condition = sessionStorage.getItem("course_all");
      if (condition) {
        this.coursetype = JSON.parse(condition);
      } else {
        gettypeconfiglist(params)
          .then((response) => {
            if (response.data && response.data.length > 0) {
              response.data.forEach((item) => {
                this.coursetype.push({
                  id: item.id,
                  name: item.name,
                });
              });
            }
          })
          .catch(() => {});
      }
    },
    todetail(item) {
      if (Number(item.child_type)) {
        // 耕读类型
        this.$router.push({
          path: "/home/wisdompracticeteaching/coursedetails",
          query: {
            course_sn: item.course_sn,
            courseType: item.child_type,
          },
        });
      } else {
        if (item.is_join == 1) {
          // 已报名
          this.$router.push({
            path: "/home/wisdompracticeteaching/courselearning",
            query: {
              course_sn: item.course_sn,
            },
          });
        } else {
          this.$router.push({
            path: "/home/wisdompracticeteaching/coursedetails",
            query: {
              course_sn: item.course_sn,
            },
          });
        }
      }
    },

    getCourseList() {
      this.courselist = [];
      // if (this.isMobile == 0) {
      //   this.queryParams.course_type_config_id = 1554
      // }
      getCourseList(this.queryParams)
        .then((response) => {
          if (response.data.data && response.data.data.length >= 0) {
            this.total = response.data.total;
            this.courselist = response.data.data;
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },
  },
};
</script>
<style scoped lang="scss">
.con_contain {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 640px) {
    .mainpage {
      background: #ffffff;
      width: 100%;

      .head {
        margin-top: 84px;
        display: flex;
        justify-content: center;
        align-items: center;

        .head-title {
          font-size: 20px;

          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        min-height: 150px;
        background: #fbfbfb;
        border-radius: 4px;
        padding-bottom: 10px;
        // padding-top: 30px;
        // padding-left: 30px;
        // padding-right: 10px;
        // padding-bottom: 5px;

        .titlecategory-item {
          margin-bottom: 20px;
          // display: flex;
          // justify-content: flex-start;
          // align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
            height: 35px;
            line-height: 35px;
            margin-left: 10px;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 10px;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 25px;
              font-size: 14px;
              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
              height: 24px;
              line-height: 24px;
              background: rgba(61, 132, 255, 0.1);
              border-radius: 12px;
              text-align: center;
              padding: 0 15px;
            }
          }
        }
      }

      .image-contain {
        margin-top: 10px;

        .image-item {
          margin-bottom: 50px;
          width: calc(100% - 10px);
          overflow: hidden;
          padding: 5px;

          .image-item-item {
            margin-bottom: 10px;
            width: calc((100% - 20px) / 2);
            float: left;
            padding: 5px;
            cursor: pointer;

            .card-contain {
              background: #ffffff;
              border-radius: 4px;
              overflow: hidden;

              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 100%;
                height: 160px;
              }
              .elimage1 {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage1:hover,
              .elimage1:focus,
              .elimage1:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage1 {
                width: 75%;
                height: 160px;
              }

              .title2 {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .name {
                  margin-left: 10px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                }

                .rightimage {
                  width: 100px;

                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  .elimage {
                    width: 16px;
                    height: 16px;
                  }

                  .text {
                    margin-right: 10px;
                    margin-left: 4px;
                    font-size: 12px;
                    text-align: left;
                    color: #999;
                  }
                }
              }

              .card-title {
                margin-top: 15px;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #222222;
                width: 95%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }

              .card-footer {
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon-title {
                  margin-left: 10px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #666666;
                }

                .icon-text {
                  background-image: url(~@/assets/矩形图标.png);
                  width: 108px;
                  height: 24px;
                  text-align: center;
                  color: #288add;
                }
              }
            }
          }
        }
      }

      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }

  @media only screen and (min-width: 640px) and (max-width: 1200px) {
    .mainpage {
      background: #ffffff;
      width: 100%;

      .head {
        margin-top: 80px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;

        .head-title {
          font-size: 20px;

          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        min-height: 150px;
        background: #fbfbfb;
        border-radius: 4px;
        padding-bottom: 10px;
        // padding-top: 30px;
        // padding-left: 30px;
        // padding-right: 10px;
        // padding-bottom: 5px;

        .titlecategory-item {
          margin-bottom: 20px;
          // display: flex;
          // justify-content: flex-start;
          // align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
            height: 35px;
            line-height: 35px;
            margin-left: 10px;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 10px;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 25px;
              font-size: 14px;
              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
              height: 24px;
              line-height: 24px;
              background: rgba(61, 132, 255, 0.1);
              border-radius: 12px;
              text-align: center;
              padding: 0 15px;
            }
          }
        }
      }

      .image-contain {
        margin-top: 10px;

        .image-item {
          margin-bottom: 50px;
          width: calc(100% - 10px);
          overflow: hidden;
          padding: 5px;

          .image-item-item {
            margin-bottom: 10px;
            width: calc((100% - 40px) / 4);
            float: left;
            padding: 5px;
            cursor: pointer;

            .card-contain {
              background: #ffffff;
              border-radius: 4px;
              overflow: hidden;

              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 100%;
                height: 160px;
              }
              .elimage1 {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage1:hover,
              .elimage1:focus,
              .elimage1:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage1 {
                width: 75%;
                height: 160px;
              }

              .title2 {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .name {
                  margin-left: 10px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                }

                .rightimage {
                  width: 100px;

                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  .elimage {
                    width: 16px;
                    height: 16px;
                  }

                  .text {
                    margin-right: 10px;
                    margin-left: 4px;
                    font-size: 12px;
                    text-align: left;
                    color: #999;
                  }
                }
              }

              .card-title {
                margin-top: 15px;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #222222;
                width: 95%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }

              .card-footer {
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon-title {
                  margin-left: 10px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #666666;
                }

                .icon-text {
                  background-image: url(~@/assets/矩形图标.png);
                  width: 108px;
                  height: 24px;
                  text-align: center;
                  color: #288add;
                }
              }
            }
          }
        }
      }

      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .mainpage {
      background: #ffffff;
      width: 1760px;

      .head {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .head-title {
          font-size: 20px;

          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        min-height: 150px;
        background: #fbfbfb;
        border-radius: 4px;
        padding: 30px 10px 5px 30px;

        .titlecategory-item {
          margin-bottom: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 15px;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 30px;
              font-size: 14px;
              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
              height: 24px;
              line-height: 24px;
              background: rgba(61, 132, 255, 0.1);
              border-radius: 12px;
              text-align: center;
              padding: 0 15px;
            }
          }
        }
      }

      .image-contain {
        margin-top: 10px;

        .image-item {
          margin-bottom: 50px;

          width: 100%;
          overflow: hidden;

          .image-item-item {
            margin-bottom: 10px;

            width: calc((100% - 120px) / 6);
            float: left;
            padding: 10px;
            cursor: pointer;

            .card-contain {
              background: #ffffff;
              border-radius: 4px;
              overflow: hidden;

              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 100%;
                height: 160px;
              }
              .elimage1 {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage1:hover,
              .elimage1:focus,
              .elimage1:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage1 {
                width: 75%;
                height: 160px;
              }

              .title2 {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .name {
                  margin-left: 10px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                }

                .rightimage {
                  width: 100px;

                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  .elimage {
                    width: 16px;
                    height: 16px;
                  }

                  .text {
                    margin-right: 10px;
                    margin-left: 4px;
                    font-size: 12px;
                    text-align: left;
                    color: #999;
                  }
                }
              }

              .card-title {
                margin-top: 15px;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #222222;
                width: 95%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }

              .card-footer {
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon-title {
                  margin-left: 10px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #666666;
                }

                .icon-text {
                  background-image: url(~@/assets/矩形图标.png);
                  width: 108px;
                  height: 24px;
                  text-align: center;
                  color: #288add;
                }
              }
            }
          }
        }
      }

      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }
}

.join_status {
  position: relative;
}

.join_status::before {
  z-index: 30;
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0;
  height: 0;
  border-top: 70px solid #3d84ff;
  border-right: 70px solid transparent;
}

.farm_tag {
  z-index: 50;
  left: 0px;
  top: 14px;
  position: absolute;
  color: #fff;
  font-size: 16px;
  font-weight: 550;
  transform: rotateZ(-45deg);
}
</style>
